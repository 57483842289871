<template>
  <div id="DxE-Service">
    <mom-page
      title="Request e-Service"
      show-breadcrumb
      :breadcrumb="[
        {
          text: 'myMomPortal  ',
          href: '#',
        },
        {
          text: 'Work Passes and Permits',
          href: '#',
        },
      ]"
    >
      <mom-page-header
        slot="header"
        user-name="User Name"
        allow-logout
        :googleAnalyticsDetails="[
          {
            gEventName: 'FormLoaded',
            gtagId: `FormLoaded`,
            gtagIsEvent: true,
            gtagIsException: false,
            gtagEventCategory: 'App Page Loaded',
            gtagEventLabel: 'App Page Loaded',
            gtagCustomIdentifier: 'App Page Loaded',
          },
          {
            gEventName: 'StepCompletion',
            gtagId: `FormSubmitButton`,
            gtagIsEvent: true,
            gtagIsException: false,
            gtagEventCategory: 'App Page Loaded',
            gtagEventLabel: 'App Page Loaded',
            gtagCustomIdentifier: 'App Page Loaded',
          },
        ]"
      />
      <mom-layout-wrapper>
        <mom-multi-step-form
          :current-step="formStep"
          :steps="[
            {
              label: 'Eligibility Check',
            },
            {
              label: 'Address',
            },
            {
              label: 'Payment',
            },
            {
              label: 'Review',
            },
          ]"
        >
          <mom-single-step-form
            v-if="formStep === 0"
            hideBackButton
            continue-button-text="Continue"
            @save="onSubmit('Form saved as draft')"
            @continue="onNext(0)"
            :googleAnalyticsDetailsContinue="[
              {
                gEventName: 'Step1_Complete',
                gtagId: `FormSubmitButton`,
                gtagIsEvent: true,
                gtagIsException: false,
                gtagEventCategory: 'Step1-Eligibility',
                gtagEventLabel: 'Step1 Completion',
                gtagCustomIdentifier: 'Step1 Completion',
              },
              {
                gEventName: 'StepCompletion',
                gtagId: `FormSubmitButton`,
                gtagIsEvent: true,
                gtagIsException: false,
                gtagEventCategory: 'Step1-Eligibility',
                gtagEventLabel: 'Step1 Completion',
                gtagCustomIdentifier: 'Step1 Completion',
              },
            ]"
            :formSections="[
              {
                title: 'Personal Details',
              },
              {
                title: 'Employment Details',
              },
            ]"
          >
            <div slot="section0">
              <mom-form-group id-for-input="input1">
                <mom-form-label
                  slot="label"
                  tooltip="Please provide your name as in NRIC"
                >
                  Name*
                </mom-form-label>
                <mom-input-text
                  id-for-input="Username"
                  v-model="username"
                  :googleAnalyticsDetails="[
                    {
                      gEventName: 'TaskInitiation',
                      gtagId: `Username`,
                      gtagIsEvent: true,
                      gtagIsException: false,
                      gtagEventCategory: 'Form Fill Start',
                      gtagEventLabel: 'Task Initiation',
                      gtagCustomIdentifier: 'Register Name',
                    },
                  ]"
                />
              </mom-form-group>
              <mom-form-group id-for-input="input2">
                <mom-form-label slot="label" tooltip="Current active email">
                  Email Address*
                </mom-form-label>
                <mom-input-text size="m" id-for-input="input2" />
              </mom-form-group>
              <mom-form-group id-for-input="input3">
                <mom-form-label slot="label" tooltip="Age as of today in years">
                  Age
                </mom-form-label>
                <mom-input-text suffix="years" size="s" id-for-input="input3" />
              </mom-form-group>

              <mom-form-group id-for-input="input6">
                <mom-form-label slot="label">
                  Date Of Birth <span class="fs:i c:grey-50">(optional)</span>
                </mom-form-label>
                <mom-input-date-dropdown id-for-input="input6" />
              </mom-form-group>
              <mom-form-group
                id-for-input="input7"
                label="Stayed long-term in Singapore (not as a tourist)?"
              >
                <mom-input-textarea
                  placeholder="If the answer to any of the above questions is YES, please provide the details"
                  id-for-input="input7"
                />
              </mom-form-group>
            </div>
            <div slot="section1">
              <mom-form-group label="Work Permit*" id-for-input="input4">
                <mom-input-select
                  id-for-input="IdentificationType"
                  :options="[
                    {
                      label: 'Singapore Citizen/PR',
                      description: 'Description',
                      value: '1',
                    },
                    {
                      label: 'Employment Pass/Others',
                      description: 'Description',
                      value: '2',
                    },
                  ]"
                />
              </mom-form-group>
              <mom-form-group id-for-input="input5">
                <mom-form-label
                  slot="label"
                  tooltip="Please provide your NRIC/FIN/Passport Number"
                >
                  Identification Number*
                </mom-form-label>
                <mom-input-text
                  :input-state="isFilled"
                  id-for-input="IdentificationNumber"
                  :googleAnalyticsDetails="googleAnalyticsError"
                  v-model="identification"
                />
              </mom-form-group>
              <mom-form-group id-for-input="input8">
                <mom-form-label slot="label" tooltip="Tooltip text">
                  If others, select one
                </mom-form-label>
                <mom-input-checkbox
                  id-for-input="input8"
                  name="input-checkbox1"
                  :value="[]"
                  :options="[
                    {
                      label: 'Dependants Pass',
                      value: '1',
                    },
                    {
                      label: 'Long Term Visit Pass',
                      value: '2',
                    },
                    {
                      label: 'Training Work Permit',
                      value: '3',
                    },
                    {
                      label: 'Training Employment Pass',
                      value: '4',
                    },
                    {
                      label: 'S Pass',
                      value: '5',
                    },
                  ]"
                />
              </mom-form-group>
              <mom-form-group id-for-input="input10" size="l">
                <mom-form-label slot="label" tooltip="Tooltip text">
                  Upload identification documents
                  <span class="fs:i c:grey-50">(optional)</span>
                </mom-form-label>
                <mom-modal
                  slot="modal"
                  toggle-type="link"
                  toggle-text="View guide"
                  toggle-size="s"
                >
                  <p class="mom-p">Form group modal</p>
                </mom-modal>
                <mom-upload
                  id-for-input="input10"
                  url="fakeAPI/post"
                  main-text="Drag and drop or browse files"
                  sub-text="Pdf only."
                />
              </mom-form-group>
            </div>
          </mom-single-step-form>

          <mom-single-step-form
            v-if="formStep === 1"
            continue-button-text="Continue to payment"
            @continue="onNext(1)"
            @back="onBack(1)"
            @save="onSubmit('Form saved as draft')"
            :googleAnalyticsDetailsContinue="[
              {
                gEventName: 'Step2_Complete',
                gtagId: `FormSubmitButton`,
                gtagIsEvent: true,
                gtagIsException: false,
                gtagEventCategory: 'Step2-Address',
                gtagEventLabel: 'Step2 Completion',
                gtagCustomIdentifier: 'Step2 Completion',
              },
              {
                gEventName: 'StepCompletion',
                gtagId: `FormSubmitButton`,
                gtagIsEvent: true,
                gtagIsException: false,
                gtagEventCategory: 'Step2-Address',
                gtagEventLabel: 'Step2 Completion',
                gtagCustomIdentifier: 'Step2 Completion',
              },
            ]"
            :formSections="[
              {
                title: 'Address Details',
              },
            ]"
          >
            <div slot="section0">
              <mom-form-group id-for-input="input11">
                <mom-form-label slot="label" tooltip="Tooltip text">
                  Full Address
                </mom-form-label>
                <mom-input-text />
              </mom-form-group>
              <mom-form-group id-for-input="input13">
                <mom-form-label slot="label">
                  Postal Code
                </mom-form-label>
                <mom-input-text size="m" id-for-input="postal" />
              </mom-form-group>
              <mom-form-group id-for-input="input12">
                <mom-form-label slot="label" tooltip="Contact Number">
                  Contact <span class="fs:i c:grey-50">(optional)</span>
                </mom-form-label>
                <mom-input-text prefix="+65" size="m" id-for-input="contact" />
              </mom-form-group>

              <mom-form-group label="Label" id-for-input="input14">
                <mom-form-label slot="label">
                  Country <span class="fs:i c:grey-50">(optional)</span>
                </mom-form-label>
                <mom-input-select
                  id-for-input="countery"
                  :options="[
                    {
                      label: 'Singapore',
                      description: 'Description',
                      value: '1',
                    },
                    {
                      label: 'Malaysia',
                      description: 'Description',
                      value: '2',
                    },
                  ]"
                />
              </mom-form-group>

              <mom-form-group id-for-input="input17" label="Other details">
                <mom-input-textarea
                  placeholder="Postbox, State"
                  id-for-input="input17"
                />
              </mom-form-group>
            </div>
          </mom-single-step-form>

          <mom-single-step-form
            v-if="formStep === 2"
            continue-button-text="Continue to review"
            @save="onSubmit('Form saved as draft')"
            @continue="onNext(2)"
            @back="onBack(2)"
            :googleAnalyticsDetailsContinue="[
              {
                gEventName: 'Step3_Complete',
                gtagId: `FormSubmitButton`,
                gtagIsEvent: true,
                gtagIsException: false,
                gtagEventCategory: 'Step3-Payment',
                gtagEventLabel: 'Step3 Completion',
                gtagCustomIdentifier: 'Step3 Completion',
              },
              {
                gEventName: 'StepCompletion',
                gtagId: `FormSubmitButton`,
                gtagIsEvent: true,
                gtagIsException: false,
                gtagEventCategory: 'Step3-Payment',
                gtagEventLabel: 'Step3 Completion',
                gtagCustomIdentifier: 'Step3 Completion',
              },
            ]"
            :formSections="[
              {
                title: 'Payment Details',
              },
            ]"
          >
            <div slot="section0">
              <div>
                <mom-button
                  class="SkipButton"
                  variant="secondary"
                  text="Skip & Add Later"
                  @click="onSkipReview"
                  :googleAnalyticsDetails="[
                    {
                      gEventName: 'AcceleratorSkipButton',
                      gtagId: `SkipPayment`,
                      gtagIsEvent: true,
                      gtagIsException: false,
                      gtagEventCategory: 'SkipPayment',
                      gtagEventLabel: 'Skip Payment',
                      gtagCustomIdentifier: 'Skip Payment',
                    },
                  ]"
                ></mom-button>
              </div>
              <mom-form-group id-for-input="input18">
                <mom-form-label slot="label" tooltip="Tooltip text">
                  Bank Name
                </mom-form-label>
                <mom-input-text placeholder="Details" id-for-input="input18" />
              </mom-form-group>

              <mom-form-group id-for-input="input21">
                <mom-form-label slot="label">
                  Card Type
                </mom-form-label>
                <mom-input-select
                  id-for-input="input21"
                  :options="[
                    {
                      label: 'Debit Card',
                      description: 'Description',
                      value: '1',
                    },
                    {
                      label: 'Credit Card',
                      description: 'Description',
                      value: '2',
                    },
                  ]"
                />
              </mom-form-group>

              <mom-form-group>
                <mom-form-label
                  slot="label"
                  tooltip="You will be redirected to your bank website"
                >
                  Card Number
                </mom-form-label>
                <mom-form-flex-wrapper>
                  <mom-form-group
                    label="Label 1"
                    size="s"
                    id-for-input="multiple8"
                    class="m-r:4"
                  >
                    <mom-input-text
                      id-for-input="multiple8"
                      placeholder="XXXX"
                      size="s"
                    />
                  </mom-form-group>

                  <div class="as:fe m-r:4">
                    <p class="m-b:8">–</p>
                  </div>

                  <mom-form-group
                    label="Label 2"
                    size="s"
                    id-for-input="multiple9"
                  >
                    <mom-input-text
                      id-for-input="multiple9"
                      placeholder="XXXX"
                      size="s"
                    />
                  </mom-form-group>
                  <div class="as:fe m-r:4">
                    <p class="m-b:8">–</p>
                  </div>

                  <mom-form-group
                    label="Label 2"
                    size="s"
                    id-for-input="multiple9"
                  >
                    <mom-input-text
                      id-for-input="multiple10"
                      placeholder="XXXX"
                      size="s"
                    />
                  </mom-form-group>
                  <div class="as:fe m-r:4">
                    <p class="m-b:8">–</p>
                  </div>

                  <mom-form-group
                    label="Label 2"
                    size="s"
                    id-for-input="multiple9"
                  >
                    <mom-input-text
                      id-for-input="multiple11"
                      placeholder="XXXX"
                      size="s"
                    />
                  </mom-form-group>
                </mom-form-flex-wrapper>
              </mom-form-group>
            </div>
          </mom-single-step-form>

          <mom-single-step-form
            v-if="formStep === 3"
            @save="onSubmit('Form completed')"
            @back="onBack(3)"
            hide-continue-button
            show-declaration
            save-button-text="Submit"
            :googleAnalyticsDetailsContinue="[
              {
                gEventName: 'Step4_Complete',
                gtagId: `FormSubmitButton`,
                gtagIsEvent: true,
                gtagIsException: false,
                gtagEventCategory: 'Step4-Review',
                gtagEventLabel: 'Step4 Completion',
                gtagCustomIdentifier: 'Step4 Completion',
              },
              {
                gEventName: 'StepCompletion',
                gtagId: `FormSubmitButton`,
                gtagIsEvent: true,
                gtagIsException: false,
                gtagEventCategory: 'Step4-Review',
                gtagEventLabel: 'Step4 Completion',
                gtagCustomIdentifier: 'Step4 Completion',
              },
            ]"
            :googleAnalyticsDetailsSave="[
              {
                gEventName: 'FormCompletion',
                gtagId: `FormSubmitButton`,
                gtagIsEvent: true,
                gtagIsException: false,
                gtagEventCategory: 'FormCompletion',
                gtagEventLabel: 'Form Completion',
                gtagCustomIdentifier: 'Form Completion',
              },
            ]"
            continue-button-text="Submit"
            download-label="Download form summary as PDF"
            checkbox-text="This is a text label"
            :formSections="[
              {
                title: 'Review details',
              },
            ]"
          >
            <div slot="section0">
              <mom-summary title="Application details">
                <div>
                  <mom-description-item
                    term="Acknowldgement Number:"
                    termWidth="l"
                    details="# XXXX XXXX"
                  /><br />
                  <mom-description-item
                    term="Applicant Name:"
                    termWidth="l"
                    :details="username"
                  />
                  <mom-description-item
                    term="Applicant identification Number:"
                    termWidth="l"
                    :details="identification"
                  /><br />
                  <mom-description-item
                    term="Payment Mode:"
                    termWidth="l"
                    details="Online Payment"
                  />
                </div>
              </mom-summary>

              <mom-summary title="Payment details">
                <div class="space-between-child">
                  <mom-table
                    full-width
                    sort-key="col1"
                    sort-order="asc"
                    :header="[
                      { key: 'col1', label: 'Name', sortable: true },
                      { key: 'col2', label: 'FIN', sortable: true },
                      { key: 'col3', label: 'Date of Expiry', sortable: true },
                      { key: 'col4', label: 'Pass Type', sortable: true },
                      { key: 'col5', label: 'Submitted By', sortable: true },
                    ]"
                    :body="[
                      {
                        col1: 'Prakash Kumar',
                        col2: 'G1234123K',
                        col3: '24 Nov 2020',
                        col4: 'Employment Pass',
                        col5: 'Veronica Williams',
                      },
                      {
                        col1: 'Grant Lee',
                        col2: 'G1234123K',
                        col3: '24 Nov 2020',
                        col4: 'Employment Pass',
                        col5: 'Jessica Shun',
                      },
                    ]"
                  />
                </div>
              </mom-summary>
            </div>
            <div slot="declaration" v-if="isLast">
              <mom-form-declaration-section>
                <p class="mom-p">Declaration text:</p>
                <ul class="mom-ul">
                  <li>
                    I hereby agree all information provided is true to my
                    knowledge.
                  </li>
                  <li>I agree to all terms and conditions.</li>
                </ul>
              </mom-form-declaration-section>
            </div>
          </mom-single-step-form>
        </mom-multi-step-form>
      </mom-layout-wrapper>
      <mom-page-footer slot="footer" />
    </mom-page>
  </div>
</template>

<script>
import {
  MomPageHeader,
  MomPageFooter,
} from "../../dxplus_release/components/page.esm";

import {
  MomPage,
  MomSingleStepForm,
  MomMultiStepForm,
} from "../../dxplus_release/template.esm";

import { MomLayoutWrapper } from "../../dxplus_release/components/layout.esm";
import {
  MomFormGroup,
  MomFormLabel,
  MomFormDeclarationSection,
  MomFormFlexWrapper,
} from "../../dxplus_release/components/form.esm";
import { MomButton } from "../../dxplus_release/components/button.esm";
import { MomInputSelect } from "../../dxplus_release/components/input_select.esm";
import { MomInputText } from "../../dxplus_release/components/input_text.esm";
import { MomInputTextarea } from "../../dxplus_release/components/input_textarea.esm";
import { MomInputDate } from "../../dxplus_release/components/datefield.esm";
import { MomInputDateDropdown } from "../../dxplus_release/components/date_dropdown.esm";
import { MomInputCheckbox } from "../../dxplus_release/components/input_checkbox.esm";
import { MomInputRadio } from "../../dxplus_release/components/input_radio.esm";
import { MomModal } from "../../dxplus_release/components/modal.esm";
import { MomDescriptionItem } from "../../dxplus_release/components/description.esm";
import { MomTable } from "../../dxplus_release/components/table.esm";
import { MomSummary } from "../../dxplus_release/components/summary.esm";
import { MomUpload } from "../../dxplus_release/components/upload.esm";

export default {
  name: "DxERequestForm",
  components: {
    MomPage,
    MomMultiStepForm,
    MomSingleStepForm,
    MomPageHeader,
    MomPageFooter,
    MomLayoutWrapper,
    MomFormGroup,
    MomFormLabel,
    MomInputDate,
    MomInputDateDropdown,
    MomButton,
    MomInputSelect,
    MomInputText,
    MomInputCheckbox,
    MomInputRadio,
    MomModal,
    MomDescriptionItem,
    MomTable,
    MomFormDeclarationSection,
    MomSummary,
    MomUpload,
    MomInputTextarea,
    MomFormFlexWrapper,
  },
  data() {
    return {
      formStep: 0,
      isFilled: "success",
      username: "",
      identification: "",
      googleAnalyticsError: [
        {
          gEventName: "FeatureUse",
          gtagId: `IdentificationNumber`,
          gtagIsEvent: true,
          gtagIsException: false,
          gtagEventCategory: "Added_IdentificationNumber",
          gtagEventLabel: "IdentificationNumber_Filled",
          gtagExceptionDescription: "",
          gtagIsFatalException: false,
          custom_name: "",
        },
      ],
    };
  },
  computed: {
    isLast: function() {
      if (this.formStep == 3) return true;
      return false;
    },
  },
  methods: {
    onNext(i) {
      if (this.validateMandatory()) this.formStep = i + 1;
      else {
        this.isFilled = "error";
        alert("Please fill all mandatory fields");
      }
    },

    onBack(i) {
      this.formStep = i - 1;
    },

    onSubmit(message) {
      alert(message);
      this.$router.push({ path: "Acknowledgement" });
    },
    onSkipReview() {
      this.formStep = 3;
    },
    validateMandatory() {
      if (this.identification) return true;
      this.googleAnalyticsError = [
        {
          gEventName: "InputError",
          gtagId: `ErrorIdentificationNumber`,
          gtagIsEvent: false,
          gtagIsException: true,
          gtagEventCategory: "",
          gtagEventLabel: "",
          gtagExceptionDescription: "Missing Identification Number",
          gtagIsFatalException: false,
          gtagCustomIdentifier: "Requestor Id No Error",
        },
      ];
      return false;
    },
  },
  mounted() {},
};
</script>

<style>
.MomMultiStepForm__StepIndicator {
  padding: 3rem;
  display: flex;
  justify-content: center;
}

.MomPage__Breadcrumb {
  padding: 1rem;
}

.MomPage__Title {
  padding-left: 2rem;
}

.MomSingleStepForm__Section {
  margin-bottom: 2rem;
}

.MomPage .MomFormActionButton {
  margin-bottom: 0rem;
}

.MomFormActionButton {
  padding-top: 1rem;
}

.SkipButton {
  margin-bottom: 1rem;
}

.MomInputText--input-state-success {
  border: 1px solid #bbc5d0 !important;
}

.MomInputText--input-state-error > .MomInputText__Input:focus {
  outline: none !important;
}

.MomInputText__Input:focus {
  outline: none !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"DxE-Service"}},[_c('mom-page',{attrs:{"title":"Request e-Service","show-breadcrumb":"","breadcrumb":[
      {
        text: 'myMomPortal  ',
        href: '#',
      },
      {
        text: 'Work Passes and Permits',
        href: '#',
      } ]}},[_c('mom-page-header',{attrs:{"slot":"header","user-name":"User Name","allow-logout":"","googleAnalyticsDetails":[
        {
          gEventName: 'FormLoaded',
          gtagId: "FormLoaded",
          gtagIsEvent: true,
          gtagIsException: false,
          gtagEventCategory: 'App Page Loaded',
          gtagEventLabel: 'App Page Loaded',
          gtagCustomIdentifier: 'App Page Loaded',
        },
        {
          gEventName: 'StepCompletion',
          gtagId: "FormSubmitButton",
          gtagIsEvent: true,
          gtagIsException: false,
          gtagEventCategory: 'App Page Loaded',
          gtagEventLabel: 'App Page Loaded',
          gtagCustomIdentifier: 'App Page Loaded',
        } ]},slot:"header"}),_c('mom-layout-wrapper',[_c('mom-multi-step-form',{attrs:{"current-step":_vm.formStep,"steps":[
          {
            label: 'Eligibility Check',
          },
          {
            label: 'Address',
          },
          {
            label: 'Payment',
          },
          {
            label: 'Review',
          } ]}},[(_vm.formStep === 0)?_c('mom-single-step-form',{attrs:{"hideBackButton":"","continue-button-text":"Continue","googleAnalyticsDetailsContinue":[
            {
              gEventName: 'Step1_Complete',
              gtagId: "FormSubmitButton",
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Step1-Eligibility',
              gtagEventLabel: 'Step1 Completion',
              gtagCustomIdentifier: 'Step1 Completion',
            },
            {
              gEventName: 'StepCompletion',
              gtagId: "FormSubmitButton",
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Step1-Eligibility',
              gtagEventLabel: 'Step1 Completion',
              gtagCustomIdentifier: 'Step1 Completion',
            } ],"formSections":[
            {
              title: 'Personal Details',
            },
            {
              title: 'Employment Details',
            } ]},on:{"save":function($event){return _vm.onSubmit('Form saved as draft')},"continue":function($event){return _vm.onNext(0)}}},[_c('div',{attrs:{"slot":"section0"},slot:"section0"},[_c('mom-form-group',{attrs:{"id-for-input":"input1"}},[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"Please provide your name as in NRIC"},slot:"label"},[_vm._v(" Name* ")]),_c('mom-input-text',{attrs:{"id-for-input":"Username","googleAnalyticsDetails":[
                  {
                    gEventName: 'TaskInitiation',
                    gtagId: "Username",
                    gtagIsEvent: true,
                    gtagIsException: false,
                    gtagEventCategory: 'Form Fill Start',
                    gtagEventLabel: 'Task Initiation',
                    gtagCustomIdentifier: 'Register Name',
                  } ]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input2"}},[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"Current active email"},slot:"label"},[_vm._v(" Email Address* ")]),_c('mom-input-text',{attrs:{"size":"m","id-for-input":"input2"}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input3"}},[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"Age as of today in years"},slot:"label"},[_vm._v(" Age ")]),_c('mom-input-text',{attrs:{"suffix":"years","size":"s","id-for-input":"input3"}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input6"}},[_c('mom-form-label',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Date Of Birth "),_c('span',{staticClass:"fs:i c:grey-50"},[_vm._v("(optional)")])]),_c('mom-input-date-dropdown',{attrs:{"id-for-input":"input6"}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input7","label":"Stayed long-term in Singapore (not as a tourist)?"}},[_c('mom-input-textarea',{attrs:{"placeholder":"If the answer to any of the above questions is YES, please provide the details","id-for-input":"input7"}})],1)],1),_c('div',{attrs:{"slot":"section1"},slot:"section1"},[_c('mom-form-group',{attrs:{"label":"Work Permit*","id-for-input":"input4"}},[_c('mom-input-select',{attrs:{"id-for-input":"IdentificationType","options":[
                  {
                    label: 'Singapore Citizen/PR',
                    description: 'Description',
                    value: '1',
                  },
                  {
                    label: 'Employment Pass/Others',
                    description: 'Description',
                    value: '2',
                  } ]}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input5"}},[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"Please provide your NRIC/FIN/Passport Number"},slot:"label"},[_vm._v(" Identification Number* ")]),_c('mom-input-text',{attrs:{"input-state":_vm.isFilled,"id-for-input":"IdentificationNumber","googleAnalyticsDetails":_vm.googleAnalyticsError},model:{value:(_vm.identification),callback:function ($$v) {_vm.identification=$$v},expression:"identification"}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input8"}},[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"Tooltip text"},slot:"label"},[_vm._v(" If others, select one ")]),_c('mom-input-checkbox',{attrs:{"id-for-input":"input8","name":"input-checkbox1","value":[],"options":[
                  {
                    label: 'Dependants Pass',
                    value: '1',
                  },
                  {
                    label: 'Long Term Visit Pass',
                    value: '2',
                  },
                  {
                    label: 'Training Work Permit',
                    value: '3',
                  },
                  {
                    label: 'Training Employment Pass',
                    value: '4',
                  },
                  {
                    label: 'S Pass',
                    value: '5',
                  } ]}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input10","size":"l"}},[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"Tooltip text"},slot:"label"},[_vm._v(" Upload identification documents "),_c('span',{staticClass:"fs:i c:grey-50"},[_vm._v("(optional)")])]),_c('mom-modal',{attrs:{"slot":"modal","toggle-type":"link","toggle-text":"View guide","toggle-size":"s"},slot:"modal"},[_c('p',{staticClass:"mom-p"},[_vm._v("Form group modal")])]),_c('mom-upload',{attrs:{"id-for-input":"input10","url":"fakeAPI/post","main-text":"Drag and drop or browse files","sub-text":"Pdf only."}})],1)],1)]):_vm._e(),(_vm.formStep === 1)?_c('mom-single-step-form',{attrs:{"continue-button-text":"Continue to payment","googleAnalyticsDetailsContinue":[
            {
              gEventName: 'Step2_Complete',
              gtagId: "FormSubmitButton",
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Step2-Address',
              gtagEventLabel: 'Step2 Completion',
              gtagCustomIdentifier: 'Step2 Completion',
            },
            {
              gEventName: 'StepCompletion',
              gtagId: "FormSubmitButton",
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Step2-Address',
              gtagEventLabel: 'Step2 Completion',
              gtagCustomIdentifier: 'Step2 Completion',
            } ],"formSections":[
            {
              title: 'Address Details',
            } ]},on:{"continue":function($event){return _vm.onNext(1)},"back":function($event){return _vm.onBack(1)},"save":function($event){return _vm.onSubmit('Form saved as draft')}}},[_c('div',{attrs:{"slot":"section0"},slot:"section0"},[_c('mom-form-group',{attrs:{"id-for-input":"input11"}},[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"Tooltip text"},slot:"label"},[_vm._v(" Full Address ")]),_c('mom-input-text')],1),_c('mom-form-group',{attrs:{"id-for-input":"input13"}},[_c('mom-form-label',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Postal Code ")]),_c('mom-input-text',{attrs:{"size":"m","id-for-input":"postal"}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input12"}},[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"Contact Number"},slot:"label"},[_vm._v(" Contact "),_c('span',{staticClass:"fs:i c:grey-50"},[_vm._v("(optional)")])]),_c('mom-input-text',{attrs:{"prefix":"+65","size":"m","id-for-input":"contact"}})],1),_c('mom-form-group',{attrs:{"label":"Label","id-for-input":"input14"}},[_c('mom-form-label',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Country "),_c('span',{staticClass:"fs:i c:grey-50"},[_vm._v("(optional)")])]),_c('mom-input-select',{attrs:{"id-for-input":"countery","options":[
                  {
                    label: 'Singapore',
                    description: 'Description',
                    value: '1',
                  },
                  {
                    label: 'Malaysia',
                    description: 'Description',
                    value: '2',
                  } ]}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input17","label":"Other details"}},[_c('mom-input-textarea',{attrs:{"placeholder":"Postbox, State","id-for-input":"input17"}})],1)],1)]):_vm._e(),(_vm.formStep === 2)?_c('mom-single-step-form',{attrs:{"continue-button-text":"Continue to review","googleAnalyticsDetailsContinue":[
            {
              gEventName: 'Step3_Complete',
              gtagId: "FormSubmitButton",
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Step3-Payment',
              gtagEventLabel: 'Step3 Completion',
              gtagCustomIdentifier: 'Step3 Completion',
            },
            {
              gEventName: 'StepCompletion',
              gtagId: "FormSubmitButton",
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Step3-Payment',
              gtagEventLabel: 'Step3 Completion',
              gtagCustomIdentifier: 'Step3 Completion',
            } ],"formSections":[
            {
              title: 'Payment Details',
            } ]},on:{"save":function($event){return _vm.onSubmit('Form saved as draft')},"continue":function($event){return _vm.onNext(2)},"back":function($event){return _vm.onBack(2)}}},[_c('div',{attrs:{"slot":"section0"},slot:"section0"},[_c('div',[_c('mom-button',{staticClass:"SkipButton",attrs:{"variant":"secondary","text":"Skip & Add Later","googleAnalyticsDetails":[
                  {
                    gEventName: 'AcceleratorSkipButton',
                    gtagId: "SkipPayment",
                    gtagIsEvent: true,
                    gtagIsException: false,
                    gtagEventCategory: 'SkipPayment',
                    gtagEventLabel: 'Skip Payment',
                    gtagCustomIdentifier: 'Skip Payment',
                  } ]},on:{"click":_vm.onSkipReview}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input18"}},[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"Tooltip text"},slot:"label"},[_vm._v(" Bank Name ")]),_c('mom-input-text',{attrs:{"placeholder":"Details","id-for-input":"input18"}})],1),_c('mom-form-group',{attrs:{"id-for-input":"input21"}},[_c('mom-form-label',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Card Type ")]),_c('mom-input-select',{attrs:{"id-for-input":"input21","options":[
                  {
                    label: 'Debit Card',
                    description: 'Description',
                    value: '1',
                  },
                  {
                    label: 'Credit Card',
                    description: 'Description',
                    value: '2',
                  } ]}})],1),_c('mom-form-group',[_c('mom-form-label',{attrs:{"slot":"label","tooltip":"You will be redirected to your bank website"},slot:"label"},[_vm._v(" Card Number ")]),_c('mom-form-flex-wrapper',[_c('mom-form-group',{staticClass:"m-r:4",attrs:{"label":"Label 1","size":"s","id-for-input":"multiple8"}},[_c('mom-input-text',{attrs:{"id-for-input":"multiple8","placeholder":"XXXX","size":"s"}})],1),_c('div',{staticClass:"as:fe m-r:4"},[_c('p',{staticClass:"m-b:8"},[_vm._v("–")])]),_c('mom-form-group',{attrs:{"label":"Label 2","size":"s","id-for-input":"multiple9"}},[_c('mom-input-text',{attrs:{"id-for-input":"multiple9","placeholder":"XXXX","size":"s"}})],1),_c('div',{staticClass:"as:fe m-r:4"},[_c('p',{staticClass:"m-b:8"},[_vm._v("–")])]),_c('mom-form-group',{attrs:{"label":"Label 2","size":"s","id-for-input":"multiple9"}},[_c('mom-input-text',{attrs:{"id-for-input":"multiple10","placeholder":"XXXX","size":"s"}})],1),_c('div',{staticClass:"as:fe m-r:4"},[_c('p',{staticClass:"m-b:8"},[_vm._v("–")])]),_c('mom-form-group',{attrs:{"label":"Label 2","size":"s","id-for-input":"multiple9"}},[_c('mom-input-text',{attrs:{"id-for-input":"multiple11","placeholder":"XXXX","size":"s"}})],1)],1)],1)],1)]):_vm._e(),(_vm.formStep === 3)?_c('mom-single-step-form',{attrs:{"hide-continue-button":"","show-declaration":"","save-button-text":"Submit","googleAnalyticsDetailsContinue":[
            {
              gEventName: 'Step4_Complete',
              gtagId: "FormSubmitButton",
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Step4-Review',
              gtagEventLabel: 'Step4 Completion',
              gtagCustomIdentifier: 'Step4 Completion',
            },
            {
              gEventName: 'StepCompletion',
              gtagId: "FormSubmitButton",
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Step4-Review',
              gtagEventLabel: 'Step4 Completion',
              gtagCustomIdentifier: 'Step4 Completion',
            } ],"googleAnalyticsDetailsSave":[
            {
              gEventName: 'FormCompletion',
              gtagId: "FormSubmitButton",
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'FormCompletion',
              gtagEventLabel: 'Form Completion',
              gtagCustomIdentifier: 'Form Completion',
            } ],"continue-button-text":"Submit","download-label":"Download form summary as PDF","checkbox-text":"This is a text label","formSections":[
            {
              title: 'Review details',
            } ]},on:{"save":function($event){return _vm.onSubmit('Form completed')},"back":function($event){return _vm.onBack(3)}}},[_c('div',{attrs:{"slot":"section0"},slot:"section0"},[_c('mom-summary',{attrs:{"title":"Application details"}},[_c('div',[_c('mom-description-item',{attrs:{"term":"Acknowldgement Number:","termWidth":"l","details":"# XXXX XXXX"}}),_c('br'),_c('mom-description-item',{attrs:{"term":"Applicant Name:","termWidth":"l","details":_vm.username}}),_c('mom-description-item',{attrs:{"term":"Applicant identification Number:","termWidth":"l","details":_vm.identification}}),_c('br'),_c('mom-description-item',{attrs:{"term":"Payment Mode:","termWidth":"l","details":"Online Payment"}})],1)]),_c('mom-summary',{attrs:{"title":"Payment details"}},[_c('div',{staticClass:"space-between-child"},[_c('mom-table',{attrs:{"full-width":"","sort-key":"col1","sort-order":"asc","header":[
                    { key: 'col1', label: 'Name', sortable: true },
                    { key: 'col2', label: 'FIN', sortable: true },
                    { key: 'col3', label: 'Date of Expiry', sortable: true },
                    { key: 'col4', label: 'Pass Type', sortable: true },
                    { key: 'col5', label: 'Submitted By', sortable: true } ],"body":[
                    {
                      col1: 'Prakash Kumar',
                      col2: 'G1234123K',
                      col3: '24 Nov 2020',
                      col4: 'Employment Pass',
                      col5: 'Veronica Williams',
                    },
                    {
                      col1: 'Grant Lee',
                      col2: 'G1234123K',
                      col3: '24 Nov 2020',
                      col4: 'Employment Pass',
                      col5: 'Jessica Shun',
                    } ]}})],1)])],1),(_vm.isLast)?_c('div',{attrs:{"slot":"declaration"},slot:"declaration"},[_c('mom-form-declaration-section',[_c('p',{staticClass:"mom-p"},[_vm._v("Declaration text:")]),_c('ul',{staticClass:"mom-ul"},[_c('li',[_vm._v(" I hereby agree all information provided is true to my knowledge. ")]),_c('li',[_vm._v("I agree to all terms and conditions.")])])])],1):_vm._e()]):_vm._e()],1)],1),_c('mom-page-footer',{attrs:{"slot":"footer"},slot:"footer"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }